import { render, staticRenderFns } from "./ImputationsList.vue?vue&type=template&id=733971ea&scoped=true&"
import script from "./ImputationsList.vue?vue&type=script&lang=js&"
export * from "./ImputationsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733971ea",
  null
  
)

export default component.exports
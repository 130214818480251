<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.Imputation" :create="openCreateModal">
        <h1 class="title">Imputations</h1>
      </page-header>

      <datatable :fetch="$Api.Imputation.fetchImputations" :entity="$EntitiesName.Imputation" ref="imputationList" enableDownload>
        <div slot="eotp" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.eotp }}</div>
        <div slot="description" slot-scope="{ item }" title="Description" :sortable="true">{{ item.description }}</div>
        <div slot="category" slot-scope="{ item: { category }}" title="Catégorie" :sortable="true" sortBy="category.parent.name">{{ category && category.parent.name }}</div>
        <div slot="subcategory" slot-scope="{ item: { category }}" title="Sous catégorie" :sortable="true" sortBy="category.name">{{ category && category.name }}</div>
      </datatable>
    </div>

    <modal ref="createImputationModal" @create="createImputation" title="Création d'une imputation">
      <div class="columns">
        <div class="column is-half">
          <ValidationObserver ref="form">
          <text-field label="eOTP" v-model="imputation.eotp" :inline="false" :edit="true" required data-test="sel-imputation-eotp"></text-field>
          <textarea-field
            label="Description"
            v-model="imputation.description"
            :inline="false"
            :edit="true"
            data-test="sel-imputation-description"
          ></textarea-field>
          <checkbox-field
            label="Imputation active"
            v-model="imputation.active"
            :inline="false"
            :edit="true"
            data-test="sel-imputation-active"
          />
          </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import Datatable from '../../components/elements/Datatable';

export default {
  name: 'imputations',
  components: {
    Datatable,
  },
  data() {
    return {
      imputation: {
        active: true
      },
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createImputationModal.open();
    },
    createImputation() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        const imputation = {
          ...this.imputation,
        };

        axios.post('/imputations', imputation).then((response) => {
          if (response.data.success) {
            this.imputation = {};
            this.$refs.imputationList.refresh();
            this.$refs.createImputationModal.close();
            this.$router.push({
              name: 'Imputation',
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },
};
</script>

<style scoped>
</style>
